.location-type-container {
  display: flex;
  margin-top: 3em;
  :last-child {
    margin-left: 1.2em;
  }

  .location-type-box {
    width: 10.2em;
    height: 3.2em;
    border: 1px solid #e0e0e0;
    box-sizing: border-box;
    border-radius: 4px;
    // color: #333333;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  .active-type {
    background: #f1f7fa;
    border: 1px solid #064a72;
    color: #064a72;
  }
}

.event-time-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  .event-period {
    border-bottom: 1px solid #c4c4c4;
    width: 48%;
  }
  label {
    font-family: '' Lato '';
    font-style: normal;
    font-weight: normal;
    font-size: 0.85em;
    color: #828282;
  }
  span {
    font-family: '' Lato '';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: #1c1c1c;
  }
}

.event-icon-container {
  display: flex;
  width: 100%;
  position: relative;
  img {
    background-size: cover;
    height: 11.2em;
    width: 100%;
  }
  .upload-banner-input,
  .upload-banner-input2 {
    height: 1.8em;
    width: 1.9em;
    left: 20em;
    top: 3em;
    position: absolute;
    cursor: pointer;
  }
  .upload-banner-input2 {
    width: 103px;
    opacity: 0;
  }
  p{
    bottom: 0px;
    position: absolute;
    left: 15em;
  }
}

.event-preview-container {
  width: 100%;

  .event-url-container {
    height: 3.2em;
    width: 65%;
    margin: 0 auto;
    background: #f8f8f8;
    display: flex;
    justify-content: space-between;
    border-radius: 4px 0px 0px 4px;
    .event-url-detail {
      display: flex;
      align-items: center;
      justify-content: center;
      color: #333333;
      font-size: 1em;
      padding-left: 1em;
    }

    .event-url-copy {
      width: 4.3em;
      height: 100%;
      background: #1c1c1c;
      border-radius: 0px 4px 4px 0px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .event-details-container {
    .event-title {
      font-family: Libre Baskerville;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 20px;
      color: #1c1c1c;
      width: 100%;
    }
    .event-date {
      width: 100%;
      font-family: 'Lato';
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      line-height: 16px;
      color: #27ae60;
    }
    // width: 65%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-wrap: wrap;
    .event-group {
      flex: 50%;
      font-family: 'Lato';
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 19px;
      // text-decoration-line: underline;
      // color: #333333;
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      img {
        margin-right: 10px;
      }
    }
    // .event-box {

    // }
  }
  .payment-info {
    width: 85%;
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 16px;
    padding: 15px;
    color: #828282;
    border-top: 0.05px solid #828282;
  }
}
