@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap');

.delivery__status__container {
  font-family: Inter;
  display: flex;
  flex-direction: column;
  border: 1px solid lightgrey;
  padding: 15px;
  border-radius: 4px;
}

.delivery__status__top {
  display: flex;
  justify-content: end;
}

.delivery__status__top select {
  width: 140px;
  height: 69px;
  top: 485px;
  left: 858px;
  padding: 9px 7px 16px 8px;
  gap: 0px;
  border: 1px solid lightgrey;
  border-radius: 3px;
  border: 1px 0px 0px 0px;
  opacity: 0px;
}

.delivery__status__filter p {
  width: 100px;
  height: 20px;
  gap: 0px;
  opacity: 0px;
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 20.3px;
  letter-spacing: -0.005em;
  text-align: center;
  color: #000000;
}

.delivery__status__filter option {
  display: flex;
  justify-content: space-between;
  gap: 100px;
}

.delivery__status__filter {
  display: flex;
  flex-direction: column;
}

.delivery__status__bottom {
  display: flex;
  justify-content: space-between;
}

.delivery__status__bottom .status_tab_1 {
  width: 532px;
  height: 169px;
  top: 517px;
  left: 53px;
  gap: 0px;
  opacity: 0px;
}

.delivery__Header {
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  line-height: 23.2px;
  letter-spacing: -0.005em;
  text-align: left;
  width: 141px;
  height: 23px;
  top: 68px;
  left: 16px;
  gap: 0px;
  opacity: 0px;
}

.left__top__ {
  display: flex;
  flex-direction: column;
}

.status__tab2__btn {
  width: 139px;
  height: 28px;
  top: 20px;
  left: 376px;
  padding: 4px 12px 4px 12px;
  gap: 4px;
  border-radius: 100px;
  opacity: 0px;

  cursor: pointer;
  font-family: Inter;
  background: #ff5d2e;
}

.status__tab2__Img {
  /* background: url('../../../assets/images/38de29a484edeea263758e673f565c12.png'); */
  background-size: cover;
  margin-top: 30px;
  width: 133px;
  height: 76px;
  top: 76px;
  left: 382px;
  gap: 0px;
  opacity: 0px;
}

.status_tab2 {
  display: flex;
  flex-direction: column;
  column-gap: 10px;
  width: 532px;
  height: 159px;
  top: 517px;
  left: 53px;
  gap: 0px;
  opacity: 0px;
}
