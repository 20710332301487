@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;600&display=swap');

body {
  font-family: 'Inter', sans-serif;
}

.table-container {
  width: 951px;
  height: 591px;
  gap: 0px;
  opacity: 0px;
  overflow-x: auto;
  padding: 16px;
}

.table-controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

.records-per-page {
  display: flex;
  align-items: center;
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
  line-height: 14.52px;
  text-align: left;
  color: #333333;
}

.records-per-page label {
  margin-right: 8px;
  font-weight: 600;
}

.records-per-page select {
  width: 72px;
  height: 36px;
  padding: 9px 12px 9px 12px;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid #e0e7ed;
  opacity: 0px;
}

.pagination-controls {
  display: flex;
  align-items: center;
}

.pagination-controls button {
  padding: 8px 16px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin: 0 4px;
}

.pagination-controls button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.pagination-controls span {
  font-weight: 600;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th,
td {
  border: 1px solid #ccc;
  padding: 10px;
  text-align: left;
  gap: 10px;
  opacity: 0px;
}

th {
  background-color: #f4f4f4;
  font-weight: 400;
}

tr:nth-child(even) {
  background-color: #f9f9f9;
}

tr:hover {
  background-color: #f1f1f1;
}

input[type='checkbox'] {
  cursor: pointer;
}

.table-container th:first-child,
.table-container td:first-child {
  text-align: center;
}
