@import '../../../../assets/stylesheets/index.scss';

#fund-wrapper,
#transfer-options,
#withdraw-wrapper,
#request-wrapper,
#billpayment-wrapper,
#account-wrapper,
#success-wrapper,
#receive-wrapper,
#bvn-wrapper,
#retrieve-wrapper {
  .modal-header {
    justify-content: center !important;
  }
  .close {
    border: none;
    background: transparent !important;
  }
  .fund-tab-wrap {
    background-color: #f1f7fa;
  }
  .fund-tab {
    font-size: 12px;
    @media (max-width: 426px) {
      font-size: 10px;
    }
  }
  .fund-tab-active {
    color: $primary-color;
  }
  .modal-header {
    border-bottom: none;
    background-color: #f1f7fa;
  }
  .modal-footer {
    border-top: none;
  }
  .modal-footer button {
    width: 80%;
  }
  .input-detail {
    font-size: 10px;
    color: #b6b3b3;
  }
  form {
    width: 80%;
    @media (max-width: 426px) {
      width: 100%;
    }
  }
  .copy-card {
    border: 1px solid #f2f2f2;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.06);
    border-radius: 4px;
    width: 70%;
  }
}

#bvn-wrapper {
  .modal-header {
    padding-bottom: 53px;
  }
  .bvn-why {
    position: relative;
  }
  .bvn-info-div {
    position: absolute;
    background-color: #fff;
    width: 309px;
    right: -185px;
    padding: 30px 40px 30px 40px;
    top: -84px;
    .bvn-reason {
      background: rgba(235, 87, 87, 0.1);
      font-size: 14px;
      border-radius: 4px;
    }
    border-radius: 4px;
  }
  .modal-footer button {
    margin-top: 30px !important;
  }
}

#fund-wrapper,
#request-wrapper,
#transfer-options,
#invite-people {
  .modal-content {
    height: 477px !important;
  }
}

#invite-people {
  .h-top,
  .modal-title {
    display: flex !important;
    justify-content: space-between;
    width: 100%;
    border: 0 !important;
    h5 {
      margin: 0;
    }
  }
  .modal-body {
    padding-top: 0 !important;
  }
  .invite-wrapper {
    // background: #ffffff;
    box-shadow: 2px 1px 4px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
  }
}

#request-wrapper {
  .modal-body {
    overflow: auto;
  }
}

#receive-wrapper {
  .modal-title {
    display: inline-block;
    width: 100%;
    h5 {
      display: inline-block;
    }
    .ml-auto {
      // display: inline-block;
      float: right;
    }
  }
}

#privacy-setting {
  .modal-header {
    display: inline-block;
  }
  .modal-body {
    padding: 1em 2em;
  }
  .profile-header-container {
    display: flex;
    align-items: center;
    width: 30%;
    justify-content: space-between;
    img {
      width: 0.75em;
      height: 0.75em;
      cursor: pointer;
    }
  }
  .menu-items {
    display: flex;
    box-shadow: 2px 1px 4px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    margin-bottom: 15px !important;
    cursor: pointer;
    min-height: 3.62em;
    h5 {
      font-size: 1em;
      font-family: Lato;
    }
  }
  .small-text {
    font-size: 0.7em;
  }
  select {
    width: 100%;
    box-shadow: 2px 1px 4px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    min-height: 3.62em;
  }
}
