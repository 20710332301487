@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap');

/* .quote__raisedQuoteForm {
  width: 100%;
} */

.quote__container {
  font-family: Inter, sans-serif;
  font-size: 0.85rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 50px;
  margin: 15px;
  box-sizing: border-box;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 4px;
  overflow-y: scroll;
}

.quote__header {
  font-size: 32px;
  font-weight: 600;
  line-height: 38.4px;
  letter-spacing: -0.4px;
  color: #000;
  text-align: left;
}

.quote__description {
  display: flex;
  flex-direction: column;
  width: 70%;
  gap: 20px;
  font-weight: 400;
  line-height: 28.8px;
  letter-spacing: -0.4px;
  color: #666;
  text-align: left;
}

.quote__bid-form {
  background: #fff;
  border-radius: 10px;
  width: 100%;
  height: 100%;
}

.quote__form-group {
  margin-bottom: 15px;
}

.quote__form-group label {
  display: block;
  font-family: 'Inter';
  font-size: 13px;
  width: 572px;
  height: 18px;
  line-height: 18px;
  color: #2c2c2e;
  margin-bottom: 5px;
  font-weight: 500;
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
}

.quote__form-group input,
.quote__form-group select,
.quote__form-group textarea {
  width: 100%;
  height: 58px;
  font-size: 0.8rem;
  padding: 8px 16px 8px 16px !important;
  border: 1px solid #aeaeb2 !important;
  border-radius: 8px !important;
  appearance: none;
  background: url('data:image/svg+xml;charset=US-ASCII,<svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10"><polygon fill="%23ccc" points="0,0 5,5 10,0"/></svg>')
    no-repeat right 10px center/10px 10px;
}

.quote__raisedQuoteForm {
  width: 80%;
}

.quote__submit-button {
  width: 40%;
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px 24px;
  color: #fff;
  border: none;
  border-radius: 12px;
  background: #ff5d2e;
  margin-top: 50px;
}

.quote__submit-button:hover {
  background-color: #e04e2e;
}

.back__btn {
  padding-bottom: 10px;
  cursor: pointer;
}

.quote-flex-form-group {
  display: flex;
  justify-content: space-between;
  gap: 30px;
  width: 600px;
}
