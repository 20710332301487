.bid__question {
  font-family: Inter;
  width: 400px;
  display: flex;
  flex-direction: column;
  height: 304px;
  top: 1858px;
  left: 1200px;
  padding: 24px;
  border-radius: 12px;
  box-shadow: 0px 20px 24px -4px #10182814;
}

.level__one__bx__question {
  display: flex;
  width: 352px;
  flex-direction: column;
  height: 180px;
  gap: 16px;
}

.level__two__bx__question {
  display: flex;
  flex-direction: row;
  width: 352px;
  height: 44px;
  gap: 12px;
  opacity: 0px;
}

.quote__discard__btn,
.quote__send__btn {
  width: 170px;
  height: 44px;
  gap: 0px;
  border-radius: 8px;
  opacity: 0px;
}

.bid__question__header {
  font-family: Inter;
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  text-align: left;
  color: #101828;
}

.bid__question__placeholder {
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: #475467;
}

.bid__question__input {
  width: 352px;
  height: 44px;
  padding: 10px 14px 10px 14px !important;
  font-size: 12px;
  gap: 8px;
  border-radius: 8px;
  border: 1px;
  opacity: 0px;
  border: 1px solid #d0d5dd;
  box-shadow: 0px 1px 2px 0px #1018280d;
}

.quote__discard__btn {
  width: 170px;
  height: 44px;
  padding: 10px 18px 10px 18px;
  gap: 8px;
  font-family: Inter;
  color: #344054;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: center;
  background: #ffffff;
  border: 1px solid #d0d5dd;
  box-shadow: 0px 1px 2px 0px #1018280d;
}

.quote__send__btn {
  font-family: Inter;
  background: #ff5d2e;
  color: white;
  border: none;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  border: 1px solid #d0d5dd;
  text-align: center;
}

.level__two__bx__question__container {
  display: flex;
  gap: 12px;
}

/* 
.confirm-delivery svg {
  margin-top: 52px;
  width: 48px;
  height: 48px;
  padding: 12px 0px 0px 0px;
  border-radius: 28px 0px 0px 0px;
  border: 8px 0px 0px 0px;
}

.confirm-delivery h5 {
  font-size: 24px;
  font-weight: 600;
  line-height: 33.6px;
  letter-spacing: -0.03em;
  text-align: center;
  width: 166px;
  height: 34px;
  top: 109px;
  left: 118px;
  gap: 0px;
}

.confirm__delivery__token {
  display: flex;
  justify-content: center;
  width: 332px;
  height: 52px;
  top: 244px;
  left: 35px;
  gap: 4px;
}

.confirm__delivery__token input {
  border: 1px solid #ff4400;
  text-align: center;
  width: 52px;
  height: 52px;
  gap: 0px;
  border-radius: 12px;
}

.level__two__bx {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
}

.level__two__bx .confirmation__header {
  height: 34px;
  top: 109px;
  left: 118px;
  gap: 0px;
  font-family: Lato;
  font-size: 24px;
  font-weight: 600;
  line-height: 33.6px;
  letter-spacing: -0.03em;
  text-align: center;
}

.level__two__bx .confirmation__msg {
  width: 319px;
  height: 44px;
  top: 149px;
  left: 41px;
  gap: 0px;
  font-family: Lato;
  font-size: 18px;
  font-weight: 500;
  line-height: 21.6px;
  text-align: center;
  color: #beb5b5;
  font-family: Inter;
} */

/* New CSS for modal and overlay */
.bid-question-modal-overlay {
  position: fixed;
  width: 400px;
  height: 304px;
  top: 1858px;
  left: 1200px;
  padding: 24px 0px 0px 0px;
  gap: 32px;
  border-radius: 12px 0px 0px 0px;
  opacity: 0px;
  width: 352px;
  height: 180px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.bid-question-modal-content {
  position: relative;
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}
