@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap');

body {
  font-family: 'Inter', sans-serif;
}

.bidding-list-container,
.product-card {
  font-family: 'Inter', sans-serif;
}

.bidding-list-container {
  width: 100%;
  overflow-y: scroll;
}

.display__message {
  display: flex;
  justify-content: center;
  text-align: center;
  font-size: 12px;
  font-weight: 600;
}

.bidding-list-table {
  width: 100%;
  border-collapse: collapse;
}

.bidding-list-header-row,
.bidding-list-row {
  width: 100%;
  color: #333333;
}

.bidding-list-header-cell,
.bidding-list-cell {
  border: 1px solid #ddd;
  padding: 16px 12px;
}

.bidding-list-header-cell {
  background: #f4f4f4;
  font-size: 0.8rem;
}

.bidding-list-cell {
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
}

.bidding-list-accept-button {
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 12px 16px;
  background: #ff5722;
  color: #fff;
  border: none;
  border-radius: 8px;
  cursor: pointer;
}

.bid-details {
  width: 184px;
  font-size: 12px;
  font-weight: 700;
  line-height: 24px;
  text-align: left;
  background: #fff;
  color: #ff5722;
  border: 1px solid #e2e2e2;
}

.ask-question div {
  text-decoration: underline;
  width: 103px;
  height: 24px;
  opacity: 0;
  font-size: 12px;
  font-weight: 700;
  line-height: 24px;
  text-align: left;
  color: #ff9273;
  cursor: pointer;
}

.delivery__status__container {
  display: flex;
  flex-direction: column;
  border: 1px solid lightgrey;
  padding: 15px;
  border-radius: 4px;
}

.delivery__status__top {
  display: flex;
  justify-content: end;
}

.delivery__status__top select {
  width: 140px;
  height: 69px;
  padding: 9px 7px 16px 8px;
  border: 1px solid lightgrey;
  border-radius: 3px;
  border-top: 1px solid lightgrey;
  opacity: 0;
}

.delivery__status__filter p {
  width: 100px;
  height: 20px;
  opacity: 0;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  color: #000000;
}

.delivery__status__filter option {
  display: flex;
  justify-content: space-between;
}

.delivery__status__filter {
  display: flex;
  flex-direction: column;
}

.delivery__status__bottom {
  display: flex;
  justify-content: space-between;
}

.delivery__status__bottom .status_tab_1 {
  width: 532px;
  height: 169px;
  opacity: 0;
}

.delivery__Header {
  font-size: 16px;
  font-weight: 600;
  line-height: 23.2px;
  text-align: left;
  width: 141px;
  height: 23px;
  opacity: 0;
}

.left__top__ {
  display: flex;
  flex-direction: column;
}

.status__tab2__btn {
  width: 139px;
  height: 28px;
  padding: 4px 12px;
  border-radius: 100px;
  opacity: 0;
  cursor: pointer;
  background: #ff5d2e;
}

.status__tab2__Img {
  background-size: cover;
  margin-top: 30px;
  width: 133px;
  height: 76px;
  opacity: 0;
}

.status_tab2 {
  display: flex;
  flex-direction: column;
  column-gap: 10px;
  width: 532px;
  height: 159px;
  opacity: 0;
}

.table-container {
  width: 951px;
  height: 591px;
  overflow-x: auto;
  padding: 16px;
}

.table-controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

.records-per-page {
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: 400;
  color: #333333;
}

.records-per-page label {
  margin-right: 8px;
  font-weight: 600;
}

.records-per-page select {
  width: 72px;
  height: 36px;
  padding: 9px 12px;
  border-radius: 8px;
  border: 1px solid #e0e7ed;
  opacity: 0;
}

.pagination-controls {
  display: flex;
  align-items: center;
}

.pagination-controls button {
  padding: 8px 16px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin: 0 4px;
}

.pagination-controls button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.pagination-controls span {
  font-weight: 600;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th,
td {
  border: 1px solid #ccc;
  padding: 16px 12px;
}

th {
  background-color: #f4f4f4;
  font-weight: 600;
}

tr:nth-child(even) {
  background-color: #f9f9f9;
}

tr:hover {
  background-color: #f1f1f1;
}

input[type='checkbox'] {
  cursor: pointer;
}

.table-container th:first-child,
.table-container td:first-child {
  text-align: center;
}

.place-bid-popup {
  background-color: white;
  border-radius: 10px;
  padding: 20px;
  width: 400px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.popup-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.popup-header h3 {
  margin: 0;
  font-size: 1.5em;
  font-weight: bold;
}

.close-btn {
  background: none;
  border: none;
  font-size: 1.5em;
  cursor: pointer;
}

.input-label {
  font-weight: bold;
  margin-bottom: 10px;
}

.bid-time-info {
  font-size: 0.9em;
  color: #777;
  margin-bottom: 20px;
}

.input-group {
  margin-bottom: 20px;
}

.input-field,
.comment-field {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 1em;
}

.comment-field {
  height: 100px;
  resize: none;
}

.place-bid-btn {
  width: 100%;
  padding: 10px;
  background-color: #f56342;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 1.2em;
  cursor: pointer;
  margin-bottom: 20px;
}

.place-bid-btn:hover {
  background-color: #e05235;
}

.confirmation-text {
  font-size: 0.9em;
  color: #777;
  text-align: center;
}

.product-card {
  width: 100%;
  height: auto;
  margin: 10px;
  padding: 20px;
}

.product-card__category {
  font-size: 12px;
  color: #999;
  text-transform: uppercase;
  margin-bottom: 10px;
}

.product-card__title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
}

.product-card__divider {
  border: 0;
  height: 1px;
  background: #ddd;
  margin-bottom: 15px;
}

.product-card__info-row {
  display: flex;
  gap: 20px;
  margin-bottom: 15px;
  font-size: 14px;
  color: #333;
}

.product-card__label {
  font-weight: normal;
  color: #666;
}

.product-card__value {
  font-weight: bold;
  text-align: justify;
  max-width: 60%;
}

.product-card__description .product-card__value {
  font-weight: normal;
}

.product-card__bid-amount-value {
  font-size: 18px;
  color: #f24b0f;
}

.product-card__readmore {
  color: #f24b0f;
  font-weight: normal;
  text-decoration: none;
}

.product-card__readmore:hover {
  text-decoration: underline;
}

.product-card__bid-button {
  background-color: #f24b0f;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  width: 100%;
  text-align: center;
}

.product-card__bid-button:hover {
  background-color: #d4410d;
}

.product-flex-card-container {
  display: flex;
  justify-content: space-between;
  gap: 30px;
  padding: 0 25px 0 0;
}

.product-description-image {
  height: 741px;
  width: 50%;
  border-radius: 8px;
  opacity: 0;
}

.product-description-image img {
  object-fit: cover;
  width: 100%;
  height: 441px;
}

.product-description-detail {
  width: 100%;
  height: 441px;
}

.info-row-btn {
  display: flex;
  justify-content: center;
  width: 50%;
}

.info-row-btn button {
  display: flex;
  justify-content: center;
  width: 50%;
}
