@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');
* {
  margin: 0;
  font-family: Inter, 'Lato', Arial;
}

.confirm-delivery {
  width: 350px;
  height: 350px;
  gap: 20px;
  border-radius: 7px;
  opacity: 0px;
  box-shadow: 8px 0 16px 0 #0000000a;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px 10px 30px 10px;
}
.confirm-delivery .svg__confirm__icon {
  /* background: url('../../../../../../../assets/bid_Icon.svg') no-repeat; */
  margin-top: 52px;
  width: 48px;
  height: 48px;
  padding: 12px 0px 0px 0px;
  border-radius: 28px 0px 0px 0px;
  border: 8px 0px 0px 0px;
}
.confirm-delivery h5 {
  font-size: 24px;
  font-weight: 600;
  line-height: 33.6px;
  letter-spacing: -0.03em;
  text-align: center;
  width: 166px;
  height: 34px;
  top: 109px;
  left: 118px;
  gap: 0px;
  opacity: 0px;
}

.confirm__delivery__token {
  display: flex;
  height: 52px;
  top: 244px;
  left: 35px;
  gap: 4px;
  opacity: 0px;
}

.confirm__delivery__token input {
  border: 1px solid #ff4400;
  width: 52px;
  height: 52px;
  gap: 0px;
  border-radius: 12px;
  border: 1px 0px 0px 0px;
  opacity: 0px;
}

.level__two__bx {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
}

.level__two__bx .confirmation__header {
  height: 34px;
  top: 109px;
  left: 118px;
  gap: 0px;
  opacity: 0px;
  font-size: 24px;
  font-weight: 600;
  line-height: 33.6px;
  letter-spacing: -0.03em;
  text-align: center;
}

.level__two__bx .confirmation_msg {
  width: 319px;
  height: 44px;
  top: 149px;
  left: 41px;
  gap: 0px;
  opacity: 0px;
  font-size: 18px;
  font-weight: 500;
  line-height: 21.6px;
  text-align: center;
}

.confirmation_btn {
  font-family: Inter;
  display: flex;
  /* margin: 20px 0; */
  padding: 24px;
  background: #ff5722;
  color: #fff;
  border: none;
  border-radius: 8px;
  align-items: center;
  justify-content: center;
}
