.product-card {
  font-family: Inter;
  width: 100%;
  height: auto;
  margin: 10px;
  padding: 20px;
  font-family: 'Inter', sans-serif;
}

.product-card__category {
  font-size: 12px;
  color: #999;
  text-transform: uppercase;
  margin-bottom: 10px;
}

.product-card__title {
  font-family: Inter;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
}

.product-card__divider {
  border: 0;
  height: 1px;
  background: #ddd;
  margin-bottom: 15px;
}

.product-card__info-row {
  display: flex;
  gap: 20px;
  margin-bottom: 15px;
  font-size: 14px;
  color: #333;
}

.product-card__label {
  font-family: Inter;
  font-weight: normal;
  color: #666;
}

.product-card__value {
  font-family: Inter;
  font-weight: bold;
  text-align: justify;
  max-width: 60%;
}

.product-card__description .product-card__value {
  font-weight: normal;
}

.product-card__bid-amount-value {
  font-size: 18px;
  color: #f24b0f;
}

.product-card__readmore {
  color: #f24b0f;
  font-weight: normal;
  text-decoration: none;
}

.product-card__readmore:hover {
  text-decoration: underline;
}

.product-card__bid-button {
  background-color: #f24b0f;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  width: 100%;
  text-align: center;
}

.product-card__bid-button:hover {
  background-color: #d4410d;
}

.product-flex-card-container {
  display: flex;
  justify-content: space-between;
  gap: 30px;
  padding: 0px 25px 0px 0px;
}

.product-description-image {
  height: 741px;
  top: 54px;
  gap: 0px;
  width: 50%;
  border-radius: 8px;
  opacity: 0px;
}

.product-description-image img {
  object-fit: cover;
  width: 100%;
  height: 441px;
}

.product-description-detail {
  width: 100%;
  height: 441px;
}

.info-row-btn {
  display: flex;
  justify-content: center;
  width: 50%;
}

.info-row-btn button {
  display: flex;
  justify-content: center;
  width: 50%;
}
