#donation-modal {
  .custom-modal-style {
    border-radius: 1.2em;
    background-color: orange;
  }

  .modal-body {
    padding: 0px !important;

    border-radius: 0 0 1.2em 1.2em;
  }

  .modal-header {
    display: inline-block;
    padding: 0px;
  }

  .modal-header > button {
    position: absolute;
    border: none;
    background-color: transparent;
    top: 2.2rem;
    right: 3rem;
  }

  .modal-content {
    border-radius: 1.2em !important;
    padding: 0px !important;
  }
  .image-div {
    width: 3.125em;
    height: 3.125em;
    margin-left: 20px;
    img {
      width: 1.88em;
      height: 1.88em;
      background-size: cover;
      border-radius: 50%;
    }
  }
}

.donation-container {
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.event-inner-content {
  margin-bottom: 25px;
  width: 80%;
}

.info-container {
  width: 90%;
  height: 100%;
  font-family: Lato;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  .input-container {
    width: 100%;
    margin-top: 1.8em;
    input,
    select,
    textarea {
      width: 100%;
      border: none;
      border-bottom: 2px solid #4f4f4f;
    }
    select {
      width: 100%;
    }
    span {
      font-size: 0.86rem;
      line-height: 16px;
      color: #828282;
    }
  }
}
.payment-info {
  width: 85%;
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 16px;
  padding: 15px;
  color: #828282;
  border-top: 0.05px solid #828282;
}

.form-check {
  .form-check-input {
    background-color: #ffffff !important;
    color: #ffffff !important;
  }
  .form-check-input:checked {
    background-color: #ff6634 !important;
    color: #ff6634 !important;
  }
}
