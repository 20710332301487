.pin-input-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1.5em;
  width: 100%;

  .pin-input-header {
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 1.5em;
    color: #1c1c1c;
  }
  .pin-input-description {
    font-family: Lato;
    font-style: normal;
    font-size: 1em;
    line-height: 19px;
    text-align: center;
    color: #828282;
  }
  .transaction-detail {
    display: flex;
    width: 84%;
    justify-content: space-between;
    align-items: center;
    .transaction-detail-right {
      font-size: 1em;
    }
  }
}
iframe {
  height: 80vh;
}

#donation-status {
  .modal-body {
    padding: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  img {
    width: 6.25em;
    margin: 2em 0px 1em 0px;
  }
  .modal-alert-foot {
    background: #ff6634;
    border-radius: 4px;
    color: #fff;
    height: 3em;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  .modal-foot {
    height: 5em;
    width: 100%;
    background: #f2f2f2;
    display: flex;
    align-items: center;
    justify-content: center;
    p {
      margin: 0px;
      color: #ff6634;
    }
    .modal-foot-group {
      display: flex;
      width: 100%;
      justify-content: space-between;
      padding: 0px 3em;
      .box {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 11em;
        height: 3.13em;
        background-color: inherit;
        color: #ff6634;
        cursor: pointer;
      }
      .active-option {
        background: #ff6634;
        border-radius: 4px;
        color: #fff;
      }
    }
  }
}
