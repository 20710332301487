.modal__close-button {
  position: absolute;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  width: 29.29px;
  height: 29.29px;
  padding: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 3.85px;
  right: 3.86px;
  gap: 0px;
  border-radius: 1.5px;
  color: #969696;
  border: 1px solid #969696;
}

.modal-input-amount {
  width: 628px;
  height: 58px;
  top: 139px;
  left: 35px;
  padding: 8px 16px 8px 16px;
  gap: 10px;
  border-radius: 8px;
  border: 1px solid #aeaeb2;
  opacity: 0px;
}

.modal__header-h2 {
  font-family: Inter;
  font-size: 26px;
  font-weight: 700;
  line-height: 37.7px;
  letter-spacing: -0.005em;
  text-align: left;
}

.bidModal-amount-label {
  font-family: Inter;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  text-align: left;
}

.modal__title {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.modal__time-remaining {
  color: #666666;
  font-family: Inter;
  margin-bottom: 1.5rem;
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  line-height: 40px;
  text-align: right;
}

p {
  font-family: Inter !important;
}

.modal__input-group {
  margin-bottom: 1.5rem;
}

.modal__label {
  display: block;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.file-upload {
  display: inline-block;
  width: 100%;
  border: 2px dashed #ccc;
  margin-bottom: 10px;
  border-radius: 5px;
  padding: 20px;
  text-align: center;
  cursor: pointer;
}

.file-upload input[type='file'] {
  display: none;
}

.file-upload label {
  display: block;
  font-size: 16px;
  color: #666;
}

.file-upload label i {
  margin-right: 10px;
}

.file-upload-info {
  font-size: 12px;
  color: #999;
  margin-top: 5px;
}

.modal__input {
  font-size: Inter;
  width: 100%;
  padding: 8px 16px 8px 16px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 15px;
}

.modal__input-file {
  width: 100%;
  height: 144px;
  top: 366px;
  left: 36px;
  padding: 40px;
  gap: 12px;
  border-radius: 3px;
  border: 1px dotted #d7dbe2;
  opacity: 0px;
}

.modal__submit-button {
  background-color: #ff5d2e;
  color: white;
  padding: 0.5rem;
  border: none;
  border-radius: 4px;
  width: 100%;
  font-size: 1rem;
  cursor: pointer;
  margin-bottom: 30px;
}

.modal__upload-group-label {
  font-family: Inter;
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: -0.02em;
  text-align: left;
}

.modal__upload-group-p {
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: #6d6d6d;
}

.modal__submit-button:hover {
  background-color: #e64a19;
}

.modal__upload-group {
  margin-bottom: 1.5rem;
}

.modal__upload-box {
  border: 2px dashed #ccc;
  padding: 1rem;
  text-align: center;
  color: #888;
}

.modal__upload-box:hover {
  border-color: #ff5722;
  color: #ff5722;
}

.modal__error {
  color: red;
  font-family: Inter;
  margin-top: 10px;
}

.modal__footer {
  font-family: inter;
  font-size: 0.9rem;
  color: #666;
  /* margin-top: 20px; */
  text-align: center;
}

.modal__textarea {
  height: 100px;
  resize: none;
  height: 163px;
  top: 530px;
  left: 37px;
  padding: 8px 2px 2px 16px;
  gap: 10px;
  border-radius: 8px;
  border: 1px solid #aeaeb2;
  opacity: 0px;
}

.modal__amount {
  font-size: 2rem;
  color: #ff5722;
  margin-bottom: 1rem;
}

.modal__control-btn {
  background-color: #ff5722;
  color: white;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
}

.modal__control-btn:hover {
  background-color: #e64a19;
}

.modal__controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.bidModal-amount-header {
  display: flex;
  justify-content: space-between;
}
