.overlay {
  width: 100vw;
  height: 100vh;
  background-color: rgba(
    0,
    0,
    0,
    0.7
  ); /* Increased opacity for a darker overlay */
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  backdrop-filter: blur(4px);
}

.overlay .content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 12;
  background-color: #ffffff; /* Added background color for contrast */
  padding: 20px; /* Added padding for spacing */
  border-radius: 8px; /* Added border-radius for a smoother appearance */
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.3); /* Added shadow for depth */
}
