@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap');

/* General layout adjustments */
.bidding__dashboard__items {
  font-family: Inter;
  height: 100vh;
  border: 1px solid #e7eaee;
  left: 0;
  top: 0px;
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 100%;
  gap: 20px;
  padding: 5px 50px 50px 50px;
  box-sizing: border-box;
}

/* Flex adjustments for responsive design */
.bidding__stats {
  font-family: Inter;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.bidding__stat {
  font-family: Inter;
  display: flex;
  justify-content: space-between;
  background: #fff;
  width: 479px;
  max-width: 485px;
  height: auto;
  margin: 5px;
  padding: 16px;
  gap: 12px;
  border-radius: 8px;
  border: 1px solid #e7eaee;
  text-align: center;
  position: relative;
}

.bidding__badge {
  background-color: #f56630;
  color: white;
  border-radius: 12px;
  width: 24px;
  height: 17px;
  padding: 0px 8px 0px 8px;
  font-size: 12px;
  margin-left: 5px;
}

/* Button styling */
.bidding__quote__button {
  font-family: Inter;
  display: block;
  margin: 20px 0;
  padding: 16px 24px 16px 24px;
  background: #ff5722;
  color: #fff;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  width: 290px;
  height: 56px;
  max-width: 290px;
}

.bidding__accept__bid__btn {
  font-family: Inter;
  width: 100%; /* Full width on small screens */
  max-width: 184px; /* Maximum width */
  height: 48px;
  padding: 12px 16px;
  background: #ff5722;
  color: #fff;
  border: none;
  border-radius: 8px;
  cursor: pointer;
}

/* Table styling */
table tr {
  width: 100%; /* Adjust to fit container */
}

table td {
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
}

/* Filter tab styling */
.bidding__bid-filter-tab {
  font-family: Inter;
  display: flex;
  gap: 10px;
  width: 100%;
  height: 52px;
  border: none;
  border-bottom: 1px solid #e7eaee;
}

/* Clickable tab styling */
.bidding__clickable {
  font-family: Inter;
  padding: 10px;
  font-size: 0.9rem;
  color: #667185;
  border-bottom: 2px solid transparent;
  cursor: pointer;
  transition: border-bottom-color 0.5s ease-in;
}

/* Active state styling */
.bidding__active {
  border-bottom: 2px solid #ff5722;
}

/* Stat component styling */
.bidding__stat-left {
  font-family: Inter;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;
}

.bidding__stat-left-text {
  font-family: Inter;
  font-size: 1rem; /* Relative unit */
}

.bidding__stat-left-p {
  font-family: Inter;
  display: flex;
  align-items: center;
  gap: 5px;
}

.bidding__percentageHeader p {
  font-family: Inter;
  font-size: 1rem; /* Relative unit */
  font-weight: 800;
  line-height: 1;
  letter-spacing: 0.5px;
  text-align: left;
  margin: 0;
}

.bidding__percentageRate {
  font-family: Inter;
  background: #ecfdf5;
  width: auto; /* Adjust width based on content */
  padding: 2px;
  border-radius: 4px;
}

.bidding__percentageRate p {
  font-family: Inter;
  margin: 0;
  font-size: 0.875rem; /* Relative unit */
  color: #3b82f6; /* Example color */
}

.bidding__quote__button__class {
  font-family: Inter;
  display: flex;
  justify-content: end;
}

.bidding__stat-right {
  font-family: Inter;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

/* Media Queries */
@media (max-width: 768px) {
  .bidding__dashboard__items {
    font-family: Inter;
    width: 100%; /* Full width on small screens */
    left: 0; /* Align with the left edge */
  }

  .bidding__stat {
    font-family: Inter;
    width: calc(100% - 20px); /* Full width on small screens */
  }

  .bidding__quote__button,
  .bidding__accept__bid__btn {
    font-family: Inter;
    width: 100%; /* Full width on small screens */
  }
}
