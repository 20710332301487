#invite-team {
  .modal-content {
    padding: 35px !important;
  }
  .modal-header {
    border-bottom: none;
  }
}
div.links {
  a {
    display: block;
  }
}
.invite-modal {
  .modal-content {
    padding: 16px;
  }
}
